<template>
  <div class="sign-panel-container">
    <div v-if="!finishSign">
      <div class="btn-wrap">
        <van-button @click="$router.go(-1)">返回</van-button>
        <van-button @click="$refs.SignCanvas.canvasClear()">清空</van-button>
        <van-button type="info" @click="confirm">确认</van-button>
      </div>
      <div class="canvas-wrap">
        <sign-canvas class="sign-canvas" ref="SignCanvas" :options="options" />
      </div>
    </div>
    <div v-else>
      <div class="sign-preview-wrap">
        <van-image class="sign-preview-img" width="300" height="150" :src="signBase64Data" />
      </div>
      <van-row style="margin: 10px">
        <van-col :offset="1">请使用合同关联的手机号获取验证码后输入，以验证身份：</van-col>
      </van-row>
      <van-password-input
        :value="captcha"
        :gutter="10"
        :mask="false"
        :length="6"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
      />
      <van-number-keyboard v-model="captcha" :show="showKeyboard" @blur="showKeyboard = false" />
      <van-row style="margin: 15px 0" type="flex" align="center">
        <van-col :offset="1">
          <van-count-down
            v-show="!showReCaptcha"
            ref="countDown"
            :time="60000"
            :auto-start="false"
            format="ss秒后重新获取"
            @finish="showReCaptcha = true"
          />
        </van-col>
        <van-col>
          <van-button size="small" @click="sendCaptcha" v-show="showReCaptcha">
            获取验证码
          </van-button>
        </van-col>
      </van-row>
      <van-cell-group style="margin: 15px 0">
        <van-field
          v-model="email"
          input-align="right"
          label="收件邮箱"
          placeholder="未设置收件邮箱，请设置"
        />
      </van-cell-group>
      <van-button type="info" block :disabled="captcha.length != 6" @click="submit" :loading="submitLoading">
        确认签名为本人签署，并悉知合同条款内容
      </van-button>
    </div>
  </div>
</template>

<script>
import SignCanvas from "sign-canvas";
import { reqVerifyCode, reqSignContract } from "@/api/sign";
export default {
  components: {
    SignCanvas,
  },
  data() {
    return {
      signBase64Data: null,
      initBase64Data:null,
      finishSign: false,
      options: {
        isSign: true,
        bgColor: "#FFF",
        borderColor: "#000",
        canvasWidth: 300,
        canvasHeight: 600,
        isShowBorder: false,
      },
      captcha: "",
      email: "",
      showKeyboard: true,
      showReCaptcha: true,
      submitLoading:false,
    };
  },
  mounted() {
    this.$notify({
      type: "warning",
      message: "请横屏签下您的姓名并确认",
      duration: 3000,
    });
    this.email = this.$store.state.contract.contract.email;
    this.initBase64Data = this.$refs.SignCanvas.saveAsImg();
    console.log(this.initBase64Data)
  },
  computed: {
    contractId() {
      return this.$store.state.contract.contractId;
    },
  },
  watch: {
    captcha() {
      if (this.captcha.length == 6) {
        this.showKeyboard = false;
      }
    },
  },
  methods: {
    confirm() {
      let buffer = this.$refs.SignCanvas.context.getImageData(0,0,300,600).data
      let count = 0;
      for (let i = 0; i < buffer.length; i++) {
        if (buffer[i] !== 255){
          count++;
        }
      }
      console.log(count)
      if(count < 2000){
        this.$toast.fail("请先完成签名");
        return;
      }
      // 旋转 90 度
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      const scale = 0.3;
      canvas.width = this.options.canvasHeight * scale;
      canvas.height = this.options.canvasWidth * scale;
      ctx.translate(0, canvas.height);
      ctx.scale(scale, scale);
      ctx.rotate((-90 * Math.PI) / 180);
      ctx.drawImage(this.$refs.SignCanvas.canvas, 0, 0);
      this.signBase64Data = canvas.toDataURL("image/png");

      this.finishSign = true;
      // this.sendCaptcha();
    },
    sendCaptcha() {
      this.showReCaptcha = false;
      reqVerifyCode(this.contractId)
        .then((res) => {
          this.$toast.success("发送成功");
          this.$refs.countDown.reset();
          this.$refs.countDown.start();
        })
        .catch((err) => {
          this.showReCaptcha = true;
        });
    },
    submit() {
      this.submitLoading = true
      reqSignContract({
        contractId: this.contractId,
        code: this.captcha,
        email: this.email,
        base64Sign: this.signBase64Data,
      }).then((res) => {
        if (res.ret) {
          this.$toast.success("签署成功");
          this.$router.replace("/sign/" + this.contractId);
        } else {
          this.$toast.fail("签署失败" + res.errMsg);
        }
      }).finally(()=>{
        this.submitLoading = false
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-panel-container {
  height: 100%;

  .canvas-wrap {
    background-color: #d8d8d8;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .btn-wrap {
    padding: 50px;

    .van-button {
      margin-left: 20px;
      transform: rotate(90deg);
    }
  }

  .sign-preview-wrap {
    display: flex;
    justify-content: center;
    background-color: #d8d8d8;
    padding: 10px 0;

    .sign-preview-img {
      background-color: white;
    }
  }
}
</style>
