import request from '@/utils/request'

export function reqVerifyCode(id) {
    return request({
        url: `/sign/captcha/send/${id}`,
        method: 'get',
    })
}

export function reqSignContract(data) {
    return request({
        url: '/sign/contract/party/b',
        method: 'post',
        data
    })
}